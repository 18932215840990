import { createContactForm } from '../graphql/mutations'
import { mutate } from '../lib/api-client'

export interface ContactFormData {
  name: string
  email: string
  message: string
}

interface CreateContactFormResponse {
  id: string
  name: string
  email: string
  message: string
  createdAt: string
  status: string
}

export const submitContactForm = async (formData: ContactFormData) => {
  try {
    const response = await mutate<{
      createContactForm: CreateContactFormResponse
    }>(createContactForm, { input: formData })

    if (!response?.createContactForm) {
      throw new Error('Invalid response format')
    }

    return response.createContactForm
  } catch (error: unknown) {
    console.error('Error submitting contact form:', error)

    if (error instanceof Error) {
      // Provide user-friendly error messages
      if (error.message.includes('Network error')) {
        throw new Error(
          'Unable to connect to the server. Please check your internet connection.'
        )
      } else if (error.message.includes('not found')) {
        throw new Error(
          'Service temporarily unavailable. Please try again later.'
        )
      } else if (
        error.message.includes('401') ||
        error.message.includes('403')
      ) {
        throw new Error(
          'Service temporarily unavailable. Please try again later.'
        )
      } else if (error.message.includes('Validation error')) {
        throw new Error('Please check your input and try again.')
      }
    }

    throw new Error('Failed to submit form. Please try again.')
  }
}
