export const createContactForm = /* GraphQL */ `
  mutation CreateContactForm($input: CreateContactFormInput!) {
    createContactForm(input: $input) {
      id
      name
      email
      message
      createdAt
      status
    }
  }
`

export const createBlogPost = /* GraphQL */ `
  mutation CreateBlogPost($input: CreateBlogPostInput!) {
    createBlogPost(input: $input) {
      slug
      title
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      readingTime
      featuredImage
      status
    }
  }
`

export const updateBlogPost = /* GraphQL */ `
  mutation UpdateBlogPost($input: UpdateBlogPostInput!) {
    updateBlogPost(input: $input) {
      slug
      title
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      readingTime
      featuredImage
      status
    }
  }
`

export const deleteBlogPost = /* GraphQL */ `
  mutation DeleteBlogPost($input: DeleteBlogPostInput!) {
    deleteBlogPost(input: $input) {
      slug
      title
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      readingTime
      featuredImage
      status
    }
  }
`

export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      title
      slug
      projectType
      status
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      featuredImage
      thumbnailImage
      contentImage
      projectStatus
      githubUrl
      liveUrl
      techStack
    }
  }
`

export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      title
      slug
      projectType
      status
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      featuredImage
      thumbnailImage
      contentImage
      projectStatus
      githubUrl
      liveUrl
      techStack
    }
  }
`

export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($slug: String!) {
    deleteProject(slug: $slug)
  }
`
