// GraphQL query definitions
export const getBlogPost = `
  query GetBlogPost($slug: String!) {
    getBlogPost(slug: $slug) {
      slug
      title
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      readingTime
      featuredImage
      status
    }
  }
`

export const listProjects = `
  query ListProjects {
    listProjects {
      id
      title
      slug
      projectType
      status
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      featuredImage
      thumbnailImage
      contentImage
      projectStatus
      githubUrl
      liveUrl
      techStack
    }
  }
`

export const listBlogPosts = `
  query ListBlogPosts {
    listBlogPosts {
      items {
        title
        slug
        status
        tags
        excerpt
        content
        author
        publishedAt
        createdAt
        updatedAt
        readingTime
        featuredImage
      }
    }
  }
`

export const GET_PROJECT = `
  query GetProject($slug: String!) {
    getProject(slug: $slug) {
      id
      title
      slug
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      featuredImage
      thumbnailImage
      contentImage
      status
      projectType
      projectStatus
      githubUrl
      liveUrl
      techStack
    }
  }
`
