/**
 * Simple GraphQL API Client for AppSync
 * Uses fetch for direct GraphQL operations
 */
import { GraphQLError } from 'graphql'

interface GraphQLResponse<T> {
  data?: T
  errors?: GraphQLError[]
}

// Debug mode for detailed logging
const DEBUG = true
const log = {
  info: (...args: unknown[]) => DEBUG && console.log('[API]', ...args),
  error: (...args: unknown[]) => DEBUG && console.error('[API Error]', ...args)
}

// Validate environment variables
if (
  !import.meta.env.VITE_APPSYNC_ENDPOINT ||
  !import.meta.env.VITE_APPSYNC_API_KEY
) {
  console.error('Missing required environment variables for API client:', {
    hasEndpoint: !!import.meta.env.VITE_APPSYNC_ENDPOINT,
    hasApiKey: !!import.meta.env.VITE_APPSYNC_API_KEY,
    mode: import.meta.env.MODE,
    baseUrl: import.meta.env.BASE_URL
  })
  throw new Error('Missing required environment variables')
}

const API_ENDPOINT = import.meta.env.VITE_APPSYNC_ENDPOINT
const API_KEY = import.meta.env.VITE_APPSYNC_API_KEY

console.log(
  'API client initialized with endpoint:',
  API_ENDPOINT.substring(0, 20) + '...'
)

// Execute a GraphQL query
export async function query<T>(
  query: string,
  variables?: Record<string, unknown>
): Promise<T> {
  log.info('Executing GraphQL query:', {
    queryName: query.split('{')[0].trim(),
    variables,
    endpoint: API_ENDPOINT.substring(0, 20) + '...',
    apiKeyLength: API_KEY ? API_KEY.length : 0
  })

  try {
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY
      },
      body: JSON.stringify({
        query,
        variables
      })
    })

    // Log detailed response info
    log.info('GraphQL response status:', response.status, response.statusText)

    if (!response.ok) {
      const errorText = await response.text()
      console.error(
        `HTTP error from AppSync! Status: ${response.status}`,
        errorText
      )

      // Detailed error handling based on status codes
      if (response.status === 401 || response.status === 403) {
        console.error(
          'API Key authentication failed! Check your API key validity and expiration'
        )
      } else if (response.status === 400) {
        console.error(
          'Bad request to AppSync! Check your query syntax and variables'
        )
      } else if (response.status >= 500) {
        console.error(
          'AppSync server error! The service might be experiencing issues'
        )
      }

      throw new Error(
        `HTTP error from AppSync! Status: ${response.status} - ${errorText}`
      )
    }

    const result = (await response.json()) as GraphQLResponse<T>

    if (DEBUG) {
      console.log('GraphQL response:', {
        hasData: !!result.data,
        hasErrors: !!result.errors,
        errorCount: result.errors?.length,
        dataKeys: result.data ? Object.keys(result.data) : []
      })
    }

    if (result.errors?.length) {
      console.error('GraphQL errors from AppSync:', result.errors)

      // Check for specific error types
      const authErrors = result.errors.filter(
        (e) =>
          e.message.includes('auth') ||
          e.message.includes('token') ||
          e.message.includes('unauthorized') ||
          e.message.includes('permission')
      )

      if (authErrors.length > 0) {
        console.error(
          'Authentication errors detected! Check your API key validity and permissions'
        )
      }

      throw new Error(result.errors[0].message)
    }

    if (!result.data) {
      console.error('No data returned from AppSync API')
      throw new Error('No data returned from AppSync API')
    }

    log.info('Successfully received data from AppSync')
    return result.data
  } catch (error) {
    console.error('GraphQL query failed:', error)

    // More detailed error logging
    if (error instanceof TypeError && error.message.includes('fetch')) {
      console.error('Network error: Could not connect to AppSync endpoint', {
        endpoint: API_ENDPOINT.substring(0, 20) + '...',
        error
      })
    } else if (error instanceof SyntaxError) {
      console.error('Invalid JSON in response from AppSync', {
        error
      })
    }

    // Rethrow with more details
    if (error instanceof Error) {
      throw new Error(`AppSync API error: ${error.message}`)
    }

    throw error
  }
}

// Execute a GraphQL mutation
export async function mutate<T>(
  mutation: string,
  variables?: Record<string, unknown>
): Promise<T> {
  return query<T>(mutation, variables)
}
