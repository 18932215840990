import { getBlogPost, listBlogPosts } from '../graphql/queries'
import {
  createBlogPost,
  updateBlogPost,
  deleteBlogPost
} from '../graphql/mutations'
import { BlogPost, BlogPostListResponse, BlogPostResponse } from '../types/blog'
import { query, mutate } from '../lib/api-client'

// Environment check
const isProduction = process.env.NODE_ENV === 'production'
const DEBUG = !isProduction
const BASE_URL = isProduction ? import.meta.env.VITE_BASE_URL || '' : ''

// Function to fetch static blog data in production environments
async function getStaticBlogData(): Promise<BlogPostListResponse | null> {
  try {
    if (DEBUG) console.group('Fetching static blog data')
    const url = `${BASE_URL}/data/blog-posts.json`
    console.log('Fetching from URL:', url)
    const response = await fetch(url)

    if (!response.ok) {
      console.error('Failed to fetch static blog data:', response.statusText)
      if (DEBUG) console.groupEnd()
      return null
    }

    const data = await response.json()
    if (DEBUG) console.log('Static blog data loaded successfully')
    if (DEBUG) console.groupEnd()
    return data
  } catch (error) {
    console.error('Error loading static blog data:', error)
    if (DEBUG) console.groupEnd()
    return null
  }
}

export const blogService = {
  async listPosts(
    limit = 10,
    nextToken?: string
  ): Promise<BlogPostListResponse> {
    try {
      if (DEBUG) console.group('List Posts')

      // Try DynamoDB API first
      try {
        console.log('Fetching posts from API with params:', {
          limit,
          nextToken
        })
        const response = await query<{ listBlogPosts: BlogPostListResponse }>(
          listBlogPosts,
          { limit, nextToken }
        )

        if (!response.listBlogPosts) {
          console.error('No data returned from API')
          throw new Error('No data returned from API')
        }

        console.log('Posts fetched successfully:', {
          count: response.listBlogPosts.items.length,
          hasNextPage: !!response.listBlogPosts.nextToken
        })
        if (DEBUG) console.groupEnd()

        return response.listBlogPosts
      } catch (apiError) {
        console.error('API fetch failed, trying static data:', apiError)

        // Only try static data if API fails
        if (isProduction) {
          const staticData = await getStaticBlogData()
          if (staticData) {
            console.log('Using static blog data as fallback', {
              count: staticData.items.length
            })

            // Implement pagination for static data
            const startIndex = nextToken ? parseInt(nextToken, 10) : 0
            const endIndex = startIndex + limit
            const items = staticData.items.slice(startIndex, endIndex)
            const newNextToken =
              endIndex < staticData.items.length
                ? endIndex.toString()
                : undefined

            return {
              items,
              nextToken: newNextToken,
              count: staticData.count
            }
          }
        }

        // If both API and static data fail, throw the original API error
        throw apiError
      }
    } catch (error) {
      console.error('Failed to fetch posts:', error)
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        })
      }
      if (DEBUG) console.groupEnd()
      throw new Error('Failed to fetch blog posts. Please try again later.')
    }
  },

  async getPost(slug: string): Promise<BlogPost | null> {
    try {
      if (DEBUG) console.group('Get Post')

      // In production, try to use static data first
      if (isProduction) {
        const staticData = await getStaticBlogData()
        if (staticData) {
          if (DEBUG) console.log('Searching for post in static data:', slug)
          const post = staticData.items.find((post) => post.slug === slug)

          if (post) {
            if (DEBUG) {
              console.log('Post found in static data:', {
                title: post.title,
                slug: post.slug
              })
              console.groupEnd()
            }
            return post
          } else {
            if (DEBUG) console.log('Post not found in static data:', slug)
          }
        }
      }

      // Fallback to DynamoDB API
      if (DEBUG) console.log('Fetching post with slug:', slug)

      const response = await query<{ getBlogPost: BlogPost | null }>(
        getBlogPost,
        { slug }
      )

      if (DEBUG) {
        if (response.getBlogPost) {
          console.log('Post found:', {
            title: response.getBlogPost.title,
            slug: response.getBlogPost.slug
          })
        } else {
          console.log('Post not found for slug:', slug)
        }
        console.groupEnd()
      }

      return response.getBlogPost
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to fetch post:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to fetch blog post. Please try again later.')
    }
  },

  async createPost(
    post: Omit<BlogPost, 'createdAt' | 'updatedAt'>
  ): Promise<BlogPostResponse> {
    try {
      if (DEBUG) console.group('Create Post')

      const response = await mutate<{ createBlogPost: BlogPostResponse }>(
        createBlogPost,
        { input: post }
      )

      if (!response.createBlogPost) {
        throw new Error('Failed to create blog post')
      }

      if (DEBUG) {
        console.log('Post created successfully:', {
          title: response.createBlogPost.title,
          slug: response.createBlogPost.slug
        })
        console.groupEnd()
      }

      return response.createBlogPost
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to create post:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to create blog post. Please try again later.')
    }
  },

  async updatePost(
    slug: string,
    post: Partial<BlogPost>
  ): Promise<BlogPostResponse> {
    try {
      if (DEBUG) console.group('Update Post')

      // Create a copy of the post object and remove publishedAt to preserve the original date
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { publishedAt, ...postWithoutPublishedAt } = post

      if (DEBUG) console.log('Updating post with preserved publishedAt')

      const response = await mutate<{ updateBlogPost: BlogPostResponse }>(
        updateBlogPost,
        { slug, input: postWithoutPublishedAt }
      )

      if (!response.updateBlogPost) {
        throw new Error('Failed to update blog post')
      }

      if (DEBUG) {
        console.log('Post updated successfully:', {
          title: response.updateBlogPost.title,
          slug: response.updateBlogPost.slug
        })
        console.groupEnd()
      }

      return response.updateBlogPost
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to update post:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to update blog post. Please try again later.')
    }
  },

  async deletePost(slug: string): Promise<void> {
    try {
      if (DEBUG) console.group('Delete Post')

      const response = await mutate<{ deleteBlogPost: { slug: string } }>(
        deleteBlogPost,
        { slug }
      )

      if (!response.deleteBlogPost) {
        throw new Error('Failed to delete blog post')
      }

      if (DEBUG) {
        console.log('Post deleted successfully:', {
          slug: response.deleteBlogPost.slug
        })
        console.groupEnd()
      }
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to delete post:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to delete blog post. Please try again later.')
    }
  }
}
