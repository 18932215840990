import { GET_PROJECT } from '../graphql/queries'
import {
  createProject,
  updateProject,
  deleteProject
} from '../graphql/mutations'
import { Project } from '../types/project'
import { query, mutate } from '../lib/api-client'

// Environment check
const isProduction = import.meta.env.PROD
const DEBUG = !isProduction
const BASE_URL = import.meta.env.VITE_BASE_URL || ''

// Function to fetch static project data in production environments
async function getStaticProjectData(): Promise<Project[] | null> {
  try {
    console.log('Attempting to fetch static project data...')
    console.log('Environment:', {
      isProduction,
      baseUrl: BASE_URL,
      nodeEnv: import.meta.env.MODE
    })

    const url = isProduction
      ? `${BASE_URL}/data/projects.json`
      : '/data/projects.json'
    console.log('Fetching from URL:', url)

    const response = await fetch(url)

    if (!response.ok) {
      console.error(
        'Failed to fetch static project data:',
        response.status,
        response.statusText
      )
      return null
    }

    const data = await response.json()

    // Validate the structure of the data
    if (
      !data.projects ||
      !Array.isArray(data.projects) ||
      data.projects.length === 0
    ) {
      console.warn('Static project data has an invalid structure or is empty')
      return getFallbackProjects()
    }

    console.log(
      `Successfully loaded ${data.projects.length} projects from static data`
    )
    return data.projects || []
  } catch (error) {
    console.error('Error loading static project data:', error)
    return getFallbackProjects()
  }
}

// Provide fallback projects if no data is available
function getFallbackProjects(): Project[] {
  console.log('Using fallback project data')
  return [
    {
      id: 'fallback-1',
      title: 'Fallback Project',
      slug: 'fallback-project',
      projectType: 'FULL-STACK WEB APPLICATION',
      status: 'PUBLISHED',
      excerpt:
        'This is a fallback project to ensure the sidebar works properly.',
      content: 'Content for the fallback project',
      author: 'System',
      publishedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      tags: ['Fallback', 'Demo'],
      featuredImage: '/images/projects/fallback.jpg',
      thumbnailImage: '/images/projects/fallback-thumb.jpg',
      contentImage: '/images/projects/fallback-content.jpg',
      projectStatus: 'IN_PROGRESS',
      githubUrl: 'https://github.com/',
      liveUrl: 'https://example.com/',
      techStack: ['React', 'TypeScript']
    }
  ]
}

const LIST_PROJECTS = `
  query ListProjects {
    listProjects {
      id
      title
      slug
      projectType
      status
      excerpt
      content
      author
      publishedAt
      createdAt
      updatedAt
      tags
      featuredImage
      thumbnailImage
      contentImage
      projectStatus
      githubUrl
      liveUrl
      techStack
    }
  }
`

export const projectService = {
  async listProjects(): Promise<Project[]> {
    try {
      console.log('Starting to fetch projects...')

      // In production, try static data first
      if (isProduction) {
        console.log('Production environment detected, trying static data first')
        const staticData = await getStaticProjectData()
        if (staticData && staticData.length > 0) {
          console.log(
            `Using static project data with ${staticData.length} projects`
          )
          return staticData
        }
        console.log('Static data not available, falling back to API')
      }

      // If static data is not available or in development, try API
      console.log('Fetching projects from API')
      const response = await query<{ listProjects: Project[] }>(LIST_PROJECTS)

      if (!response.listProjects) {
        console.error('No projects data received from API')
        return getFallbackProjects()
      }

      console.log(
        `Successfully fetched ${response.listProjects.length} projects from API`
      )
      return response.listProjects
    } catch (error) {
      console.error('Error fetching projects:', error)

      // Try static data as fallback
      try {
        const staticData = await getStaticProjectData()
        if (staticData && staticData.length > 0) {
          console.log(
            `Using static project data as fallback with ${staticData.length} projects`
          )
          return staticData
        }
      } catch (fallbackError) {
        console.error('Error fetching fallback data:', fallbackError)
      }

      // As a last resort, use the hardcoded fallback projects
      return getFallbackProjects()
    }
  },

  async getProject(slug: string): Promise<Project | null> {
    try {
      console.log(`Fetching project with slug: ${slug}`)

      // In production, try to use static data first
      if (isProduction) {
        console.log('Production environment detected, trying static data first')
        const staticData = await getStaticProjectData()
        if (staticData) {
          console.log('Searching for project in static data:', slug)
          const project = staticData.find((project) => project.slug === slug)

          if (project) {
            console.log('Project found in static data:', {
              title: project.title,
              slug: project.slug
            })
            return project
          } else {
            console.log('Project not found in static data:', slug)
          }
        }
      }

      // Fallback to DynamoDB API
      console.log('Fetching project from API')
      const response = await query<{ getProject: Project | null }>(
        GET_PROJECT,
        { slug }
      )

      if (response.getProject) {
        console.log('Project found:', {
          title: response.getProject.title,
          slug: response.getProject.slug
        })
      } else {
        console.log('Project not found for slug:', slug)
      }

      return response.getProject
    } catch (error) {
      console.error('Failed to fetch project:', error)
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        })
      }
      throw new Error('Failed to fetch project. Please try again later.')
    }
  },

  async createProject(
    project: Omit<Project, 'createdAt' | 'updatedAt'>
  ): Promise<Project> {
    try {
      if (DEBUG) console.group('Create Project')

      const response = await mutate<{ createProject: Project }>(createProject, {
        input: project
      })

      if (!response.createProject) {
        throw new Error('Failed to create project')
      }

      if (DEBUG) {
        console.log('Project created successfully:', {
          title: response.createProject.title,
          slug: response.createProject.slug
        })
        console.groupEnd()
      }

      return response.createProject
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to create project:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to create project. Please try again later.')
    }
  },

  async updateProject(
    slug: string,
    project: Partial<Project>
  ): Promise<Project> {
    try {
      if (DEBUG) console.group('Update Project')

      const response = await mutate<{ updateProject: Project }>(updateProject, {
        slug,
        input: project
      })

      if (!response.updateProject) {
        throw new Error('Failed to update project')
      }

      if (DEBUG) {
        console.log('Project updated successfully:', {
          title: response.updateProject.title,
          slug: response.updateProject.slug
        })
        console.groupEnd()
      }

      return response.updateProject
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to update project:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to update project. Please try again later.')
    }
  },

  async deleteProject(slug: string): Promise<boolean> {
    try {
      if (DEBUG) console.group('Delete Project')

      const response = await mutate<{ deleteProject: { slug: string } }>(
        deleteProject,
        { slug }
      )

      if (!response.deleteProject) {
        throw new Error('Failed to delete project')
      }

      if (DEBUG) {
        console.log('Project deleted successfully:', {
          slug: response.deleteProject.slug
        })
        console.groupEnd()
      }

      return true
    } catch (error) {
      if (DEBUG) {
        console.error('Failed to delete project:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          })
        }
        console.groupEnd()
      }
      throw new Error('Failed to delete project. Please try again later.')
    }
  }
}
